import React from "react";
import Head from "next/head";
import classes from "./list-item.module.css";
import Snackbar from "@material-ui/core/Snackbar";
import Link from "next/link";
import WishlistButton from "../WishlistButton/WishlistButton";
import { Alert } from "@material-ui/lab";
import Modal from "@material-ui/core/Modal";
import FormLoader from "/components/ui/FormLoader";
import * as ga from '/lib/ga'
import Image from "/components/CustomImage"
import { useListItemSale } from "./hooks/useListItemSale";
import cancel_icon from '~/public/images/cancel-icon.inline.svg';
import video_icon from '~/public/images/video-icon.inline.svg';
import delete_btn from '~/public/images/deleteNew.inline.svg';
import star from '~/public/images/star.inline.svg';
import desk_bag_white from '~/public/images/desk-bag-white.inline.svg';

function ListItem(props) {
  const {
    name,
    sku,
    id,
    url_path,
    url_key,
    type,
    conf,
    variants,
    stock,
    salableqty,
    wishlist_id,
    wishlist_item_id,
    session,
    rating_summary,
    review_count,
    product_label,
    index,
    ItemCategory3
  } = props;

  const { firstvideo,
    modalStyle,
    coreclasses,
    ref,
    showLoader,
    router,
    variantLabel,
    eventCommonObj,
    mPrice,
    cPrice,
    homeOrder,
    categoryUrlSuffix,
    websiteurl,
    mediaImage,
    strippedTitle,
    isMobileDevice,
    strippedContent,
    currency,
    vPrice,
    ConditionalWrapper,
    config,
    addtobag,
    ButtonText,
    showMe,
    toggle,
    bSnackbarVisible,
    conf0,
    isDefaultSelected,
    setPrice,
    handleMClose,
    YouTubeGetID,
    handleOpen,
    modalopen,
    removeWishlistItem,
    website_id,
    toggleDrawer,
    handleClose,
    cartMessage,
    successMessage
  } = useListItemSale(props);

  const videoIframe = firstvideo ? (
    <><a className={classes.cancelbutton} onClick={handleMClose}><img loading="lazy" width={20} height={20} alt="cancel" src={cancel_icon} /></a><iframe
      width="100%"
      height="375"
      src={`https://www.youtube.com/embed/${YouTubeGetID(
        firstvideo.video_content.video_url
      )}?rel=0`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
      loading="lazy" /></>
  ) : (
    ""
  );

  const body = (
    <div style={modalStyle} className={`${coreclasses.paper}`}>
      {videoIframe}
    </div>
  );

  return (
    <div
      ref={ref}
      id={`${id}_${index}`}
      className={`${props && props.swipe ? `col-8 ${classes["col-8"]}` : "col-8 col-md-4"
        }`}
    >
      <Head>
        <link rel="stylesheet" href="/css/sale.css" />
      </Head>
      <FormLoader show={showLoader} />

      <div className={classes.productitem}>

        <div className={`video_icon ${classes['video_icon']}`}>
          {firstvideo ? (
            <>
              <a onClick={handleOpen}>
                <img loading="lazy" src={video_icon} alt="video icon" width="30px" height={18} />
              </a>
              <Modal
                open={modalopen}
                onClose={handleMClose}
                aria-labelledby="product-video"
                aria-describedby="product-video"
              >
                {body}
              </Modal>
            </>
          ) : null}
        </div>
        {router.asPath == "/wishlist" || router.asPath == "/international/wishlist" ? (
          <div className="delete-btn">
            <form onSubmit={removeWishlistItem}>
              <input
                type="hidden"
                name="wishlistItemsIds"
                value={wishlist_item_id}
              />
              <input type="hidden" name="wishlistId" value={wishlist_id} />
              <button type="submit">
                <img loading="lazy" src={delete_btn} alt="delete icon" width={28} height={28} />
              </button>
            </form>
          </div>
        ) : (
          ""
        )}

        {router.asPath != "/wishlist" ? (
          <WishlistButton
            product={props}
            parentSku={sku}
            name={name}
            sku={
              variants && variants[0] && variants[0].product.sku
                ? variants[0].product.sku
                : ""
            }
            variantLabel={variantLabel}
            eventCommonObj={eventCommonObj}
            ItemCategory3={ItemCategory3}
            productprice={mPrice}
            productSellPrice={cPrice}
          />
        ) : (
          ""
        )}
        {homeOrder ? (
          <>
            <span>
              <Image loading="lazy" width={400} height={400} src={mediaImage} alt={name} placeholder="blur" blurDataURL="/images/kama-placeholder-kama.jpg" title={name} />
            </span>
            <h3>{strippedTitle}</h3>
          </>
        ) : (
          <Link
            prefetch={false}
            href={{
              pathname: "/_url-resolver",
              query: {
                pathname: `${url_path}${categoryUrlSuffix}`,
                type: "PRODUCT",
                url_path: url_path,
                url_key: url_key,
                cat_id: id,
              },
            }}
            as={`/${websiteurl}${url_path}${categoryUrlSuffix}`}
            onClick={() => ga.ProductClicks(props, session, website_id, variantLabel, eventCommonObj, ItemCategory3)}>

            <span>
              <Image loading="lazy" width={400} height={400} src={mediaImage} alt={name} title={name} placeholder="blur" blurDataURL="/images/kama-placeholder-kama.jpg" />
            </span>

          </Link>
        )}
        <div className={classes.category_star}>
          <h6>{product_label}</h6>
          {(rating_summary / 20).toFixed(1) > 2 ? <div className={classes.category_row}> {(rating_summary / 20).toFixed(1)} <img loading='lazy' src={star}
            width={11}
            height={10} alt="star icon" />
            ({review_count})
          </div> : null}</div>
        <Link
          prefetch={false}
          href={{
            pathname: "/_url-resolver",
            query: {
              pathname: `${url_path}${categoryUrlSuffix}`,
              type: "PRODUCT",
              url_path: url_path,
              url_key: url_key,
              cat_id: id,
            },
          }}
          as={`/${websiteurl}${url_path}${categoryUrlSuffix}`}
          onClick={() => ga.ProductClicks(props, session, website_id, variantLabel, eventCommonObj, ItemCategory3)}>

          <h3>{strippedTitle}</h3></Link>
        {!isMobileDevice ? <p>{strippedContent}</p> : null}

        <div className="stock_bag_main">
          <div className="stock_bag_bax">
            {stock == "IN_STOCK" && cPrice != 0 ? (
              <h4>
                {currency}
                {cPrice.toFixed(2)}
                {vPrice && (vPrice > 0) && (vPrice > cPrice) ?
                  <span className={classes.cutoffprice}>{currency}{vPrice.toFixed(2)}</span>
                  : mPrice > cPrice ? <span className={classes.cutoffprice}>{currency}
                    {mPrice.toFixed(2)}</span> : null}
              </h4>
            ) : variants && variants[0] ? (
              <h4>
                {currency}
                {variants[0].product.price.regularPrice.amount.value.toFixed(2)}
                {vPrice && (vPrice > 0) && (vPrice > variants[0].product.price.regularPrice.amount.value) ?
                  <span className={classes.cutoffprice}>{currency}{vPrice.toFixed(2)}</span>
                  : ''}
              </h4>
            ) : (
              <h4>
                {currency}
                {cPrice.toFixed(2)}
              </h4>
            )}
            {(stock == "OUT_OF_STOCK" || (salableqty && salableqty <= 0)) ? (
              <button className={classes.outofstock}>Out Of Stock</button>
            ) : null}
            {(stock == "IN_STOCK" && (!salableqty || salableqty > 0)) ? (
              <div className={classes.btnbag}>
                <ConditionalWrapper
                  condition={type == "simple" || conf0.values.length == 1}
                >
                  {!config.anniversary_promotion8_title ?
                    <form onSubmit={addtobag}>
                      <ConditionalWrapper condition={type == "configurable"}>
                        <input type="hidden" name="parentsku" value={sku} />
                        <input
                          type="hidden"
                          name="sku"
                          value={
                            variants && variants[0] && variants[0].product.sku
                              ? variants[0].product.sku
                              : ""
                          }
                        />
                      </ConditionalWrapper>
                      <ConditionalWrapper condition={type == "simple"}>
                        <input type="hidden" name="sku" value={sku} />
                      </ConditionalWrapper>

                      <input type="hidden" name="qty" value="1" />

                      <button type="submit">
                        {/* <img loading="lazy" src={desk_bag_white} height={19} alt="bag icon" width="16" /> */}
                         {ButtonText}</button>
                    </form> : null
                  }
                  {config.anniversary_promotion8_title ?
                    <button type="button" onClick={toggleDrawer("right", true)}>
                      {/* <img loading="lazy" src={desk_bag_white} alt="bag icon" height={19} width="16" />  */}
                      Notify me</button> : null
                  }
                </ConditionalWrapper >
                <ConditionalWrapper
                  condition={type == "configurable" && conf0.values.length > 1}
                >
                  {!config.anniversary_promotion8_title ?
                    <form onSubmit={addtobag}>
                      <ConditionalWrapper condition={type == "configurable"}>
                        <input type="hidden" name="parentsku" value={sku} />
                      </ConditionalWrapper>

                      <input type="hidden" name="qty" value="1" />

                      <button
                        type="submit"
                        style={{
                          display: showMe ? "block" : "none",
                        }}
                      >
                        {/* <img loading="lazy" src={desk_bag_white} alt="bag icon" height={19} width="16" />  */}
                        {ButtonText}
                      </button>

                      <button
                        onClick={toggle}
                        style={{
                          display: showMe ? "none" : "block",
                        }}
                      >
                        {/* <img loading="lazy" src={desk_bag_white} alt="bag icon" height={19} width="16" />  */}
                        {ButtonText}
                      </button>
                      <div
                        style={{
                          display: showMe ? "block" : "none",
                        }}
                        className={classes.view_size_colm}
                      >
                        <div className={classes.col}>
                          <h6>Choose size</h6>
                          <a className={classes.close} onClick={toggle}>
                            <img loading="lazy" src={cancel_icon} alt="close" />
                          </a >
                        </div >
                        <table>
                          <tbody>
                            {conf?.length > 0 ?
                              variants.map((value, index) => (
                                <tr key={index}>
                                  <td>
                                    <label>
                                      <input
                                        type="radio"
                                        name="sku"
                                        placeholder=""
                                        value={variants[index].product.sku}
                                        defaultChecked={isDefaultSelected(index)}
                                        disabled={
                                          variants[index].product.stock_status ==
                                            "OUT_OF_STOCK" || (variants[index].product.salableqty.label &&
                                              variants[index].product.salableqty.label <= 0)
                                            ? true
                                            : false
                                        }
                                        onClick={setPrice}
                                      />
                                      {variants[index].attributes[0].label}
                                      <i>{variants[index].product.stock_status ==
                                        "OUT_OF_STOCK" || (variants[index].product.salableqty.label &&
                                          variants[index].product.salableqty.label <= 0)
                                        ? ' Out of Stock'
                                        : ''}</i>
                                    </label>
                                  </td>
                                  <td>
                                    {currency}
                                    {
                                      variants[index].product.price.regularPrice
                                        .amount.value.toFixed(2)
                                    }
                                    {variants[index].product.price_range?.minimum_price.regular_price
                                      .value > variants[index].product.price.regularPrice
                                        .amount.value && <abbr className={classes.cutoffprice}>{currency}
                                        {variants[index].product.price_range.minimum_price.regular_price
                                          .value.toFixed(2)}</abbr>
                                    }
                                  </td>
                                </tr>
                              )) : null}
                          </tbody>
                        </table>
                      </div >
                    </form > : null
                  }
                  {config.anniversary_promotion8_title ?
                    <button type="button" onClick={toggleDrawer("right", true)}>
                      {/* <img loading="lazy" src={desk_bag_white} alt="bag icon" height={19} width="16" /> */}
                       Notify me</button>
                    : null}
                </ConditionalWrapper>
              </div>
            ) : null}
          </div >
        </div >
      </div >

      {
        bSnackbarVisible ? (
          <Snackbar open={open} autoHideDuration={1500} onClose={handleClose} >
            <Alert onClose={handleClose} severity={(successMessage == true) ? "success" : "error"}>
              <div dangerouslySetInnerHTML={{ __html: cartMessage }} />
            </Alert>
          </Snackbar>
        ) : null}
    </div >
  );
}

export default ListItem;